// src/CreateAccount.js
import React, { useState } from 'react';
import { collection, query, orderBy, limit, getDocs, doc, setDoc, getFirestore, where } from 'firebase/firestore';
import { app } from '../firebase';
import '../css/CreateAccount.css'
import imageCompression from 'browser-image-compression';

const CreateAccount = ({ goToPage }) => {
  const [created, setCreated] = useState('')

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [username2, setUsername2] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dni, setDni] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');

  const [nationality, setNationality] = useState('');
  const [examplary, setExamplary] = useState('');
  const [tramitNumber, setTramitNumber] = useState('');
  const [emissionDate, setEmissionDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');

  const [cuilLastNumber, setCuilLastNumber] = useState('');
  const [securityNumber1, setSecurityNumber1] = useState('');
  const [securityNumber2, setSecurityNumber2] = useState('');
  const [securityNumber3, setSecurityNumber3] = useState('');
  const [securityNumber4, setSecurityNumber4] = useState('');

  const [base64Image, setBase64Image] = useState('');

  const [optionalData, setOptionalData] = useState('none');

  const optionalDataShow = () => {
    if (optionalData === 'none') {
      setOptionalData('block')
    } else {
      setOptionalData('none')
    }
  }


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 0.75 * 1024 * 1024; // 750KB

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')) {
      if (file.size > maxSize) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
          fileType: file.type,
        };

        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error compressing the image:', error);
          alert('Error al comprimir la imagen.');
        }
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      event.target.value = '';
      setBase64Image('');
      alert('Por favor subir un archivo JPG, PNG, o WEBP.');
    }
  };


  const db = getFirestore(app);

  const checkExistingUser = async (username) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('user', '==', username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreated('Creando cuenta...')

    if (username === username2 && password === password2) {
      try {
        const userExists = await checkExistingUser(username);
        if (userExists) {
          alert('El usuario ya está registrado.');
          return;
        }

        // Obtener el último ID asignado
        const accountsRef = collection(db, "users");
        const q = query(accountsRef, orderBy("__name__", "desc"), limit(1));
        const querySnapshot = await getDocs(q);

        let lastId = "000000";
        querySnapshot.forEach((doc) => {
          lastId = doc.id;
        });

        // Calcular el nuevo ID
        const newId = String(parseInt(lastId) + 1).padStart(6, '0');

        const checkedSex = gender === 'Masculino' ? 'M' : 'F'
        const [dateOfBirthYear, dateOfBirthMonth, dateOfBirthDay] = dateOfBirth.split('-');

        const checkedNationality = nationality === '' ? 'Argentina' : nationality;
        const checkedExamplary = examplary === '' ? 'A' : examplary;
        const checkedTramitNumber = tramitNumber === '' ? '0064929313892648' : tramitNumber;
        const emissionDate2 = emissionDate === '' ? '2023-08-08' : emissionDate;
        const [emissionDateYear, emissionDateMonth, emissionDateDay] = emissionDate2.split('-')
        const expirationDate2 = expirationDate === '' ? '2038-08-08' : expirationDate;
        const [expirationDateYear, expirationDateMonth, expirationDateDay] = expirationDate2.split('-')

        const checkedCuilLastNumber = cuilLastNumber === '' ? Math.floor(Math.random() * 10) : cuilLastNumber;
        const checkedSecurityNumber1 = securityNumber1 === '' ? Math.floor(Math.random() * 10) : securityNumber1;
        const checkedSecurityNumber2 = securityNumber2 === '' ? Math.floor(Math.random() * 10) : securityNumber2;
        const checkedSecurityNumber3 = securityNumber3 === '' ? Math.floor(Math.random() * 10000000) : securityNumber3;
        const checkedSecurityNumber4 = securityNumber4 === '' ? Math.floor(Math.random() * 10) : securityNumber4;


        await setDoc(doc(db, "users", newId), {
          user: username,
          password: password,
          profilePhoto: base64Image,
          data: {
            name: firstName,
            lastname: lastName,
            dni: dni,
            home: address,
            sex: checkedSex,
            dateOfBirth: {
              day: dateOfBirthDay,
              month: dateOfBirthMonth,
              year: dateOfBirthYear.slice(-2)
            }
          },
          otherData: {
            cuilLastNumber: checkedCuilLastNumber,
            securityNumber1: checkedSecurityNumber1,
            securityNumber2: checkedSecurityNumber2,
            securityNumber3: checkedSecurityNumber3,
            securityNumber4: checkedSecurityNumber4
          },
          extraData: {
            nationality: checkedNationality,
            examplary: checkedExamplary,
            tramitNumber: checkedTramitNumber,
            emissionDate: {
              day: emissionDateDay,
              month: emissionDateMonth,
              year: emissionDateYear.slice(-2)
            },
            expirationDate: {
              day: expirationDateDay,
              month: expirationDateMonth,
              year: expirationDateYear.slice(-2)
            }
          }
        });

        setCreated('Cuenta creada!!');
        alert('Cuenta creada!!')
      } catch (e) {
        console.error("Error añadiendo documento: ", e);
      }
    }
  };

  return (
    <div id='app' className='app-create-account' style={{ backgroundColor: 'white' }}>
      <div id="login-header">
        <img src={require('../img/log-in/atras.webp')} alt="" id='atras' onClick={() => goToPage()} />
      </div>

      <h2>Crear cuenta</h2>
      <p id="details" style={{ backgroundColor: 'yellow', border: '3px solid red' }}>Por favor, intente escribir lo <b>más adecuadamente posible</b>, siguiendo las <b>mayusculas y minúsculas</b> correspondietes. Esto para evitar tener problemas con el formato del DNI.</p>

      <h3 style={{ textDecoration: 'underline' }}>Tutoriales</h3>
      <div id='tutoriales'>
        <button onClick={() => window.open('https://youtube.com/shorts/vRHlm0raDo4', '_blank')}>
          <p>Instalar Android</p>
        </button>
        <button onClick={() => window.open('https://youtube.com/shorts/sk2YhRS0CPc', '_blank')}>
          <p>Instalar Iphone</p>
        </button>
      </div>

      <form onSubmit={handleSubmit}>

        <h3 style={{ textDecoration: 'underline' }}>Datos obligatorios</h3>

        <div>
          <input
            type="email"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder='E-Mail'
          />
        </div>
        <div>
          <input
            type="email"
            id="username2"
            value={username2}
            onChange={(e) => setUsername2(e.target.value)}
            required
            placeholder='Repetir E-Mail'
          />
        </div>
        <div>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder='Contraseña'
          />
        </div>
        <div>
          <input
            type="password"
            id="password2"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            required
            placeholder='Repetir contraseña'
          />
        </div>
        <div>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, "")) }}
            required
            placeholder='Nombre/s  (Máximo 2)'
          />
        </div>
        <div>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
            required
            placeholder='Apellido  (Máximo 1)'
          />
        </div>
        <div>
          <input
            type="text"
            id="dni"
            value={dni}
            onChange={(e) => {
              setDni(e.target.value.replace(/[^0-9]/g, ""))
              if (dni.length >= 8) {
                setDni(e.target.value.substring(0, 8));
              }
            }}
            required
            placeholder='DNI'
          />
        </div>
        <div>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""))}
            required
            placeholder='Calle, ciudad y provincia'
          />
        </div>
        <div>
          <label htmlFor="gender">Sexo:</label>
          <select
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Seleccione</option>
            <option value="Femenino">Femenino</option>
            <option value="Masculino">Masculino</option>
          </select>
        </div>
        <div>
          <label htmlFor="dateOfBirth">Fecha de nacimiento:</label>
          <input
            type="date"
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
          />
        </div>
        <div>
          <label id="profileImageLabel" htmlFor="profileImage">Elegir foto de tu cara (Estilo DNI)</label>
          <input
            type="file"
            id="profileImage"
            accept="image/jpeg, image/png, image/webp"
            onChange={handleFileChange}
            required
          />
          {base64Image && (
            <div>
              <h3>Imagen subida:</h3>
              <img src={base64Image} alt="Uploaded" id='uploaded-image' style={{ aspectRatio: '77/100', maxWidth: '100%' }} />
            </div>
          )}
        </div>

        {/* Datos opcionales */}

        <div id='optional-data'>
          <div style={{all: 'unset'}} onClick={optionalDataShow}>
            <h3 style={{ textDecoration: 'underline' }}>Datos opcionales (se establecen de forma automática)</h3>
            <p>(Haz click para dezplegar)</p>
          </div>

          <div id='optional-data-form' style={{ all: 'unset', display: optionalData}}>
            <div style={{ paddingTop: '10px' }}>
              <input
                type="text"
                id="nationality"
                value={nationality}
                onChange={(e) => setNationality(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
                placeholder='Nacionalidad (Nombre del país)'
              />
            </div>
            <div>
              <label id='examplaryLabel' htmlFor="examplary">Ejemplar:</label>
              <select
                id="examplary"
                value={examplary}
                onChange={(e) => setExamplary(e.target.value)}
              >
                <option value="">Seleccione</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                id="tramitNumber"
                value={tramitNumber}
                onChange={(e) => {
                  setTramitNumber(e.target.value.replace(/[^0-9]/g, ""));
                  if (tramitNumber.length >= 15) {
                    setTramitNumber(e.target.value.substring(0, 15));
                  }
                }}
                placeholder='Número de trámite (15 dígitos)'
              />
            </div>
            <div>
              <label htmlFor="emissionDate">Fecha de emisión:</label>
              <input
                type="date"
                id="emissionDate"
                value={emissionDate}
                onChange={(e) => setEmissionDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="expirationDate">Fecha de expiración:</label>
              <input
                type="date"
                id="expirationDate"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                id="cuilLastNumber"
                value={cuilLastNumber}
                onChange={(e) => {
                  setCuilLastNumber(e.target.value.replace(/[^0-9]/g, ""));
                  if (cuilLastNumber.length >= 1) {
                    setCuilLastNumber(e.target.value.substring(0, 1))
                  }
                }}
                placeholder='Último dígito del CUIL'
              />
            </div>
            <div>
              <input
                type="text"
                id="securityNumber1"
                maxLength={1}
                value={securityNumber1}
                onChange={(e) => {
                  setSecurityNumber1(e.target.value.replace(/[^0-9]/g, ""));
                  if (securityNumber1.length >= 1) {
                    setSecurityNumber1(e.target.value.substring(0, 1))
                  }
                }}
                placeholder='Número de seguridad 1 (1 dígito)'
              />
            </div>
            <div>
              <input
                type="text"
                id="securityNumber2"
                maxLength={1}
                value={securityNumber2}
                onChange={(e) => {
                  setSecurityNumber2(e.target.value.replace(/[^0-9]/g, ""));
                  if (securityNumber2.length >= 1) {
                    setSecurityNumber2(e.target.value.substring(0, 1))
                  }
                }}
                placeholder='Número de seguridad 2 (1 dígito)'
              />
            </div>
            <div>
              <input
                type="text"
                id="securityNumber3"
                minLength={7}
                maxLength={7}
                value={securityNumber3}
                onChange={(e) => {
                  setSecurityNumber3(e.target.value.replace(/[^0-9]/g, ""));
                  if (securityNumber3.length >= 7) {
                    setSecurityNumber3(e.target.value.substring(0, 1))
                  }
                }}
                placeholder='Número de seguridad 3 (7 dígitos)'
              />
            </div>
            <div>
              <input
                type="text"
                id="securityNumber4"
                maxLength={1}
                value={securityNumber4}
                onChange={(e) => {
                  setSecurityNumber4(e.target.value.replace(/[^0-9]/g, ""));
                  if (securityNumber4.length >= 1) {
                    setSecurityNumber4(e.target.value.substring(0, 1))
                  }
                }}
                placeholder='Número de seguridad 4 (1 dígito)'
              />
            </div>
          </div>
        </div>
        <p id='created'>{created}</p>
        <div id="buttonContainer">
          <button type="submit">
            <p>Crear cuenta</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
